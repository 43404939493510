.borderBottom{
    border-bottom: 1px solid #DCDEE0;
    height: 38px;
    font-weight: 500;
    font-size: 14px;
    margin-top: 20px;
    margin-left: 20px;
}

.content{
    font-size: 14px;
    display: flex;
    padding-left: 80px;
    padding-bottom: 50px;
}
.content-left{
    text-align: right;
}
.content-left div{
    margin-top: 30px;
}
.content-right div{
    margin-top: 30px;
}
@primary-color: #275ecc;