
.login{
    // display: flex;
    width: 100%;
    height: 100%;
    
  
    padding: 0;
    margin: 0;
    // position: relative;
}
.mainBox{
    background: #f7f9fc;
    width: 100%;
}
.loginmain{
    width: 1200px;
    // background:red;
    min-width: 1200px;
    height: 640px;
    margin:  0 auto;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
}
.login_right{
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 6px 18px rgba(0,0,0,.08);
    box-sizing: border-box;
    height: 494px;
    opacity: .93;
    padding: 64px 40px 40px;
    width: 398px;
    margin-top: 84px;
    // position: absolute;
    // top: 81px;
    // right: 200px;
}
.reb{
    position: relative;
}
.abs{
    position: absolute;
    top: 20%;
    font-size: 30px;
}

.jiacu{
    font-weight: 700;
    font-size:60px;
    margin-top: 20px;
}
.smallSize{
    font-size: 20px;
    margin-top: 20px;
    letter-spacing: 20px;
}
.login_left{
 position: relative;
 overflow: hidden;
}
.tabsClass{
    position: absolute;
    bottom: 15%;
}
.btn{
    width: 318px;
height: 42px;
background: linear-gradient(90deg, #25A8FF 0%, #155BD4 100%);
border-radius: 6px;
 border: 0;
 font-size: 14px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #FFFFFF;
}
p{
    color: #A5ACB4;
}
.loginmain1{
    background: #FFFFFF;
    width: 100%;
    margin-top: 43px;
}
.smallMain{
    width: 1200px;
    min-width: 1200px;
    margin:  0 auto;
    height: 202px;
    display: flex;
    align-items: center;
}
.smallMain1{
    font-size: 40px;
    font-weight: 600;
}
.smallMain3,.smallMain4,.smallMain5{
    margin-left: 24px;
}
.smallMain2{
    margin-left: 90px;
}
.open{
  display: flex;
    align-items: center;
    justify-content: space-between;
    width: 364px;
    height: 124px;
    background: #F7F9FC;
    border-radius: 12px;
    padding-left: 16px;
}
.fontWeight{
    font-size: 20px;
    font-weight: 600;
    color: #1D2129;
    height: 28px;
}
.grey{
    color: #6B737A;
    font-size: 14px;
}
.marginLeft{
    margin-left: 13px;
}
.openImg{
    width: 151px;
    height: 124px;
}
.close1{
    width: 124px;
    height: 126px;
    background: black;
    background: url('../../images/11.png') no-repeat 100% 100%;
    background-size:cover;
    padding-top: 48px;
    padding-left: 16px;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 20px;
}
.close2{
    width: 124px;
    height: 126px;
    background: black;
    background: url('../../images/22.png') no-repeat 100% 100%;
    background-size:cover;
    padding-top: 48px;
    padding-left: 16px;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 20px;
}
.close3{
    width: 124px;
    height: 126px;
    background: black;
    background: url('../../images/33.png') no-repeat 100% 100%;
    background-size:cover;
    padding-top: 48px;
    padding-left: 16px;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 20px;
}
.close4{
    width: 124px;
    height: 126px;
    background: black;
    background: url('../../images/44.png') no-repeat 100% 100%;
    background-size:cover;
    padding-top: 48px;
    padding-left: 16px;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 20px;
}
.imgClose{
    width: 124px;
    height: 124px;
}
@primary-color: #275ecc;