.bankcard{
    width: 254px;
    height: 120px;
    border: 1px solid #000000;
    border-radius: 4px;
    .title{
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        .title-left{
            margin-left: 14px;
        }
        .title-right{
            margin-right: 14px;
        }
    }
    .main{
        margin-left: 14px;
        margin-top: 18px;
        .main1{
            font-size: 12px;
        }
        .main2{
            font-size: 16px;
        }
        .main3{
            font-size: 12px;
        }
    }
}
@primary-color: #275ecc;