.sybox{
    display: flex;
    justify-content: space-between;
}
.storeValue{
    // width: 99%;
    height: 118px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F7F8FA;
    margin-top: 20px;
    margin-left: 10px;
}
.size{
    font-size: 18px;
    margin-right: 20px;
}
.sizeSmall{
    color: #165BD4;
    font-size: 14px;
    margin-left: 20px;
}
.text{
    font-size: 14px;
    color: #646566;
    margin-top: 10px;
}
.logo{
    // margin-right: 20px;
}
@primary-color: #275ecc;