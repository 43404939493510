.bankCard{
    margin-left: 10px;
    .bankCard-top{
        width: 293px;
        height: 128px;
        // background:#C7D2EA ;
        border: 1px solid #ccc;
        border-radius:4px;
        color: black;
        .title{
            display: flex;
            justify-content: space-between;
            .logo{
                margin: 16px 0 0 16px;
                width: 32px;
                height: 32px;
            }
            .name{
                margin: 16px 0 0 9px;
                font-size: 14px;
            }
            .type{
                flex: 1;
                text-align: right;
                margin:17px 13px 0 0 ;
                font-size: 12px;
            }
        }
        .main{
            margin-left: 16px;
            margin-top: 6px;
            .gsName{
               font-size: 12px; 
            }
            .card{
                font-size: 16px;
            }
            .cardName{
                font-size: 12px; 
            }
        }
    }
    .bankCard-bottom{
        width: 293px;
        height: 40px;
        border: 1px solid #ccc;
        border-top: none;
        box-shadow: 0px 0px 2px 0px rgba(162,162,162,0.5);
        border-radius: 0 0 4px 4px;
        color: #185AD4;
        font-size: 14px;
        display: flex;
        align-items: center;
        .del{
            margin-left: 10px;
            margin-right: 13px;
        }
        .radio{
            flex: 1;
            margin-left: 16px;
        }
    }
}
.bankCard1{
    width: 293px;
    height: 168px;
    border: 1px dashed #155BD4;
    border-radius: 4px;
    margin-left: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #F7F8FA;
   .add{
        border: 2px solid #155BD4;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        font-size: 30px;
        color: #155BD4;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.bankCard:first-child{
    margin-left:0 ;
}
.box{
width:100%; 
// height: 100vh;
background: #ffffff;
// background: red;
// display: flex;
// word-break: break-all;
// word-wrap: break-word
// display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
// overflow: hidden;  
// word-break: break-all;   /* break-all(允许在单词内换行。)  对pc端可以不要*/ 
// text-overflow: ellipsis;   /* 超出部分省略号 */
// -webkit-box-orient: vertical;   /** 设置或检索伸缩盒对象的子元素的排列方式 **/
// -webkit-line-clamp: 3;   /** 显示的行数 **/
}
@primary-color: #275ecc;